/* eslint-env browser */
import React, { useEffect, useState } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { FloatingWhatsApp } from 'react-floating-whatsapp';
import { navigate } from 'gatsby';
import Content from '../components/Content';
import Menu from '../components/Menu';
import TextIcon from '../components/TextIcon';
import GoogleMaps from '../components/GoogleMaps';

const pageStyles = {
  paddingTop: '96px',
  minHeight: '100vh',
  borderBottom: '14px solid #D5C65D',
  fontFamily: 'Open Sans, -apple-system, Roboto, sans-serif, serif',
  background: '#fbfbfb',
};

const units = {
  'nucleo-infantil': {
    title: 'Núcleo Infantil Penha',
    address: 'Rua General Socrates, 390 - Penha',
    services: 'Terapia ABA, Terapia Denver, Psicopedagogia',
    cover: '/units/nucleo-infantil/0.jpg',
    images: [
      '/units/nucleo-infantil/1.jpg',
      '/units/nucleo-infantil/2.jpg',
      '/units/nucleo-infantil/3.jpg',
    ],
    wpp: '(11) 99414-6396',
    linkWpp:
      'https://api.whatsapp.com/send?phone=5511994146396&text=Ol%C3%A1%2C%20vim%20pelo%20site%20e%20gostaria%20de%20marcar%20uma%20consulta%20%3A)',
    phone: '(11) 2691-6225',
    mail: 'nucleoinfantil2@gmail.com',
    urlMap:
      'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3658.0682985361186!2d-46.5484205!3d-23.5300458!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x94ce5e5a4b8fb867%3A0xd6fc37ddb1811566!2sR.%20Gen.%20S%C3%B3crates%2C%20390%20-%20Penha%20de%20Fran%C3%A7a%2C%20S%C3%A3o%20Paulo%20-%20SP%2C%2003632-040!5e0!3m2!1spt-BR!2sbr!4v1667845515400!5m2!1spt-BR!2sbr',
  },
  penha: {
    title: 'Núcleo Pilares Penha',
    address: 'Rua Santo Afonso, 210 – Penha',
    services:
      'Psicologia, Fonoaudiologia, Audiometria, Terapia em Cabine Avaliação Neuropsicológica, Avaliações Psicológicas',
    cover: '/units/penha/0.jpg',
    images: ['/units/penha/1.jpg', '/units/penha/2.jpg'],
    wpp: '(11) 96075-0276 ',
    linkWpp:
      'https://api.whatsapp.com/send?phone=5511960750276&text=Ol%C3%A1%2C%20vim%20pelo%20site%20e%20gostaria%20de%20marcar%20uma%20consulta%20%3A)',
    phone: '(11) 2855-1110 / (11) 2691-5476',
    mail: 'pilaresrecepcao@gmail.com',
    urlMap:
      'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3658.111830494582!2d-46.55089618495046!3d-23.528479884699177!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x94ce5e5a731f5bb9%3A0xe28dc435fcf3db29!2sR.%20Santo%20Afonso%2C%20210%20-%20Penha%20de%20Fran%C3%A7a%2C%20S%C3%A3o%20Paulo%20-%20SP%2C%2003632-020!5e0!3m2!1spt-BR!2sbr!4v1594070350595!5m2!1spt-BR!2sbr',
  },
  guarulhos: {
    title: 'Núcleo Infantil Guarulhos',
    address: 'Rua João Gonçalves, 525 - Centro de Guarulhos',
    services: 'Psicologia, Fonoaudiologia, Terapia Ocupacional, Terapia ABA',
    cover: '/units/guarulhos/0.jpg',
    images: [
      '/units/guarulhos/1.jpg',
      '/units/guarulhos/2.jpg',
      '/units/guarulhos/3.jpg',
      '/units/guarulhos/4.jpg',
      '/units/guarulhos/5.jpg',
      '/units/guarulhos/6.jpg',
      '/units/guarulhos/7.jpg',
    ],
    wpp: '(11) 97220-7874',
    linkWpp:
      'https://api.whatsapp.com/send?phone=5511972207874&text=Ol%C3%A1%2C%20vim%20pelo%20site%20e%20gostaria%20de%20marcar%20uma%20consulta%20%3A)',
    phone: '(11) 4967-1397',
    mail: 'recepcaogru.pilares@gmail.com',
    urlMap:
      'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3659.8411519911433!2d-46.53232032429438!3d-23.466193658103542!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x94cef55b724f5c67%3A0x6a6e2bd22358cdee!2sR.%20Jo%C3%A3o%20Gon%C3%A7alves%2C%20525%20-%20Jardim%20Santa%20Edwirges%2C%20Guarulhos%20-%20SP%2C%2007010-010!5e0!3m2!1spt-BR!2sbr!4v1741294754977!5m2!1spt-BR!2sbr',
  },
};

const Unit = ({ name }) => {
  useEffect(() => {
    if (!units[name]) navigate('/');
  });
  const openWhatsAppChat = () => {
    document.querySelector('.whatsapp-open-button').click();
  };

  const [hasClosetWhatsAppChat, setHasClosetWhatsAppChat] = useState(false);
  const handleCloseWhatsAppChat = () => {
    window.sessionStorage.setItem('hasClosedWhatsAppChat', 'true');
  };
  useEffect(() => {
    const item = window.sessionStorage.getItem('hasClosedWhatsAppChat') || 'false';
    setHasClosetWhatsAppChat(JSON.parse(item));
  }, []);

  if (!units[name]) {
    return <div />;
  }
  return (
    <main style={pageStyles}>
      <title>{units[name].title}</title>
      <FloatingWhatsApp
        accountName="Rose da Pilares"
        avatar="/blue_logo.jpg"
        phoneNumber="5511960750276"
        statusMessage="Online"
        placeholder="Escreva uma mensagem"
        chatMessage="Olá! Sou a Rose assistente virtual, como posso ajudar?"
        notification={!hasClosetWhatsAppChat}
        notificationSoundSrc="/notification.mp3"
        notificationSound
        notificationDelay={5}
        notificationLoop={1}
        buttonClassName="whatsapp-open-button"
        onNotification={openWhatsAppChat}
        onClose={handleCloseWhatsAppChat}
      />

      <Menu />
      <Container>
        <Content
          title={units[name].title}
          subtitle={units[name].services}
          images={[units[name].cover]}
        >
          <Row>
            {units[name].images &&
              units[name].images.map((url) => (
                <Col key={url} md="6">
                  <img
                    src={url}
                    style={{
                      width: '100%',
                      height: '300px',
                      objectFit: 'cover',
                      marginBottom: '32px',
                    }}
                    alt={units[name].title}
                  />
                </Col>
              ))}
          </Row>
          <Row style={{ marginTop: '32px' }}>
            <Col md={{ span: 5, offset: 1 }}>
              <TextIcon
                label="Whatsapp"
                text={units[name].wpp}
                icon="/svgs/whatsapp.svg"
                link={units[name].linkWpp}
              />
              <TextIcon
                label="E-mail"
                text={units[name].mail}
                icon="/svgs/social-email.svg"
                link={`mailto: ${ units[name].mail }`}
              />
            </Col>
            <Col md={{ span: 5, offset: 1 }}>
              <TextIcon label="Telefone" text={units[name].phone} icon="/svgs/phone.svg" />
              <TextIcon label="Endereço" text={units[name].address} icon="/svgs/location.svg" />
            </Col>
          </Row>
          <GoogleMaps iframeUrl={units[name].urlMap} />
        </Content>
      </Container>
    </main>
  );
};

export default Unit;
